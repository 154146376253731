<template>
  <div>
    <user-account-purchases-mobile-menu v-if="$mq === $enums.mqBreakpoints.MOBILE"/>
    <user-account-purchases-tabs v-else/>
  </div>
</template>

<script>
import UserAccountPurchasesTabs from '@/views/modules/user/user-purchases/UserAccountPurchasesTabs.vue';
import UserAccountPurchasesMobileMenu
  from '@/views/modules/user/user-purchases/UserAccountPurchasesMobileMenu.vue';

/**
 * User account configurations and details
 *
 * @module views/modules/user-account/UserAccountPurchases
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 */
export default {
  name: 'UserAccountPurchases',
  components: {
    UserAccountPurchasesTabs,
    UserAccountPurchasesMobileMenu,
  },
};
</script>

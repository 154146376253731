<template>
  <vs-tabs
    position="top"
    class="tabs-shadow-none sub-tabs"
    :value="tabActive">
 <vs-tab
      :label="$t('$UserSettings.$Shop.$ManageTopUps.$TopUpAccount.TabTitle')"
      icon-pack="feather"
      icon="icon-arrow-up-circle">
      <user-account-purchases-top-up-account-info
        :pay-period="$enums.StripeEnums.Checkout.Mode.SUBSCRIPTION"/>
    </vs-tab>
    <vs-tab
      :label="$t('$UserSettings.$Shop.$ManageTopUps.$AutoTopUpAccount.TabTitle')"
      icon-pack="feather"
      icon="icon-sliders">
      <user-account-purchases-auto-top-up-account-info
        :pay-period="$enums.StripeEnums.Checkout.Mode.PAYMENT"/>
    </vs-tab>
  </vs-tabs>
</template>

<script>
import UserAccountPurchasesTopUpAccount from '@/views/modules/user/user-purchases/user-purchases-top-ups/UserAccountPurchasesTopUpAccount.vue';
import UserAccountPurchasesAutoTopUpAccount from '@/views/modules/user/user-purchases/user-purchases-top-ups/UserAccountPurchasesAutoTopUpAccount.vue';

/**
 * User account overview tabs
 *
 * @module views/modules/user-account/purchases/UserAccountPurchasesManageTopUps
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {number} [tabActive=0] - active tab index
 */
export default {
  name: 'UserAccountPurchasesManageTopUps',
  components: {
    UserAccountPurchasesTopUpAccountInfo: UserAccountPurchasesTopUpAccount,
    UserAccountPurchasesAutoTopUpAccountInfo: UserAccountPurchasesAutoTopUpAccount,
  },
  data() {
    return {
      tabActive: 0,
    };
  },
  created() {
    const routeParts = this.$route.path.split('/');
    const relevantPart = routeParts[routeParts.length - 1] || '';

    switch (relevantPart) {
      case 'auto-top-up-account':
        this.tabActive = 1;
        break;

      default:
        this.tabActive = 0;
    }
  },
};
</script>

<template>
  <dynamic-tabs
    tabs-id="user-purchases-tabs"
    :tabs="tabs"
    :relevant-url-part-offset="relevantUrlPartOffset"/>
</template>

<script>
import DynamicTabs from '@/views/modules/_components/DynamicTabs.vue';
import UserAccountPurchasesManageTopUps from '@/views/modules/user/user-purchases/user-purchases-top-ups/UserAccountPurchasesManageTopUps.vue';
import UserAccountOverview from '@/views/modules/user/user-purchases/user-my-purchases/UserMyPurchasesTabs.vue';
import UserAccountPurchasesDedicatedNumbers from '@/views/modules/user/user-purchases/UserAccountPurchasesDedicatedNumbers.vue';

/**
 * User account purchases tabs
 *
 * @module views/modules/user-account/UserAccountPurchasesTabs
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Object[]} [tabs=[...]] - tabs to show
 * @vue-data {number} relevantUrlPartOffset - offset depend on route path
 */
export default {
  name: 'UserAccountPurchasesTabs',
  components: {
    DynamicTabs,
  },
  data() {
    return {
      tabActive: 0,
      tabs: [
        {
          icon: 'icon-layers',
          label: this.$t('$UserSettings.$Shop.$MyPurchases.TabTitle'),
          i18nPageTitle: 1,
          component: UserAccountOverview,
          path: 'my-purchases',
        },
        {
          icon: 'icon-settings',
          label: this.$t('$UserSettings.$Shop.$ManageTopUps.TabTitle'),
          component: UserAccountPurchasesManageTopUps,
          path: 'manage-top-ups',
        },
        {
          icon: 'icon-smartphone',
          label: this.$t('$UserSettings.$Shop.$DedicatedNumbers.TabTitle'),
          component: UserAccountPurchasesDedicatedNumbers,
          path: 'dedicated-numbers',
        },
      ],
    };
  },
  computed: {
    relevantUrlPartOffset() {
      const routeParts = this.$route.path.split('/');

      if (routeParts[routeParts.length - 1] === 'dedicated-numbers') {
        return 0;
      }

      return 1;
    },
  },
};
</script>

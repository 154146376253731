<template>
  <div>
    <mobile-menu-group
      v-for="(group, index) in groups"
      :id="group.TabIdName"
      :key="index"
      :class="{'mt-base': index > 0}"
      :header-text="group.headerText"
      :items="group.items"/>
  </div>
</template>

<script>
import MobileMenuGroup from '@/views/modules/_components/MobileMenuGroup.vue';

/**
 * User account purchases mobile menu
 *
 * @module views/modules/user/user-purchases/UserAccountPurchasesMobileMenu
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Object[]} [groups=[...]] - groups to show in the menu
 * @vue-event {void} logout - logout
 */
export default {
  name: 'UserAccountPurchasesMobileMenu',
  i18n: {
    messages: {
      en: {
        PrepaidPackagesText: 'Manage your prepaid packages',
        TopUpAccountText: 'Upgrade your account with packages',
        AutoTopUpAccountText: 'Set up your auto top-up, so that you do not run out of balance',
        PurchaseDedicatedNumbersText: 'Acquire new dedicated numbers to use in your campaigns',
        ManageDedicatedNumbersText: 'Manage your dedicated numbers',
      },
    },
  },
  components: {
    MobileMenuGroup,
  },
  data() {
    return {
      groups: [
        {
          headerText: this.$t('$UserSettings.$Shop.$MyPurchases.TabTitle'),
          TabIdName: 'mobile-group-billingsss',
          items: [
            {
              title: this.$t('$UserSettings.$Shop.$MyPurchases.$PrepaidPackages.TabTitle'),
              text: this.$t('PrepaidPackagesText'),
              icon: 'GridIcon',
              path: '/purchases-mobile/my-purchases/my-prepaid-packages',
            },
            {
              title: this.$t('$UserSettings.$Shop.$MyPurchases.$DedicatedNumbers.TabTitle'),
              text: this.$t('ManageDedicatedNumbersText'),
              icon: 'SmartphoneIcon',
              path: '/purchases-mobile/my-purchases/my-dedicated-numbers',
            },
          ],
        },
        {
          headerText: this.$t('$UserSettings.$Shop.$ManageTopUps.TabTitle'),
          TabIdName: 'mobile-group-purchases-manage-top-ups',
          items: [
            {
              title: this.$t('$UserSettings.$Shop.$ManageTopUps.$TopUpAccount.TabTitle'),
              text: this.$t('TopUpAccountText'),
              icon: 'ArrowUpCircleIcon',
              path: '/purchases-mobile/manage-top-ups/top-up-account',
            },
            {
              title: this.$t('$UserSettings.$Shop.$ManageTopUps.$AutoTopUpAccount.TabTitle'),
              text: this.$t('AutoTopUpAccountText'),
              icon: 'SlidersIcon',
              path: '/purchases-mobile/manage-top-ups/auto-top-up-account',
            },
          ],
        },
        {
          headerText: this.$t('$UserSettings.$Shop.$DedicatedNumbers.TabTitle'),
          TabIdName: 'mobile-group-purchases',
          items: [
            {
              title: this.$tc('$General.DedicatedNumber', 2),
              text: this.$t('PurchaseDedicatedNumbersText'),
              icon: 'SmartphoneIcon',
              path: '/purchases-mobile/dedicated-numbers',
            },
          ],
        },
      ],
    };
  },
};
</script>
